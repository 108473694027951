import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

let darkTheme = (localStorage.getItem('darkTheme') === 'true') || false

export default new Vuetify({
    theme: { dark: darkTheme },
});
