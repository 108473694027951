<template>
  <v-app id="inspire">
    <v-navigation-drawer 
      v-model="drawer"
      v-if="isLoggedIn"
      app
      dense
    >
      <v-list-item >
        <v-list-item-content color="#0288D1">
          <v-list-item-title class="text-h8">
            prs-x.cc
          </v-list-item-title>
          <v-list-item-subtitle>
            Automation {{ authUserData.username }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider color="#0288D1"></v-divider>
      <v-list
        dense
        nav
      >
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :to="item.to"
          link
        >
          <v-list-item-icon v-if="item.counter">
            <v-badge
              :content="item.counter"
              :value="item.counter"
              color="green"
              overlap
              >
              <v-icon>{{ item.icon }}</v-icon>
            </v-badge>
          </v-list-item-icon>
          <v-list-item-icon v-else>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>

          </v-list-item-content>
        </v-list-item>
        <v-switch
          v-model="$vuetify.theme.dark"
          label="Dark"
          color="normal"
          @change="changeTheme($vuetify.theme.dark)"
        >
        </v-switch>
        <vnotify ref="vnotify"/>
      </v-list>
      <template v-if="isLoggedIn" v-slot:append>
        <div class="pa-2">
          <v-btn block @click="logout">
            Logout
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <vsnack ref="vsnack"/>
    <v-app-bar color="#1976D2" app >
      <v-app-bar-nav-icon @click="drawer = !drawer" v-if="isLoggedIn"></v-app-bar-nav-icon>
      
      <v-toolbar-title>PRS-X.CC</v-toolbar-title>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
    <template>
      <v-footer color="#0288D1" padless>
        <v-col
          class="text-right"
          cols="11"
        >
          {{ new Date().getFullYear() }} — <strong>prs-x.cc</strong>
        </v-col>
      </v-footer>
    </template>

  </v-app>
</template>

<script>
  import { mapGetters } from "vuex"

  import vsnack from '@/views/v-snack.vue'
  import vnotify from '@/components/Notify/Notify.vue'

  export default {
    components: {
      vsnack,
      vnotify,
    },
    data: () => ({
      drawer: null,
    }),
    computed : {
      ...mapGetters([
        'NEW_PAYMENTS_COUNT',
        'NEW_SMS_COUNT',
      ]),
      items() {
        return [
            { title: 'Главная', icon: 'mdi-home-variant', to: '/'},
            { title: 'Заявки', icon: 'mdi-credit-card-sync', to: '/Bids'},
            { title: 'Платежи', icon: 'mdi-swap-vertical-bold', to: '/Orders', counter: this.NEW_PAYMENTS_COUNT },
            { title: 'Карты', icon: 'mdi-credit-card-outline', to: '/Cards' },
            { title: 'SMS', icon: 'mdi-message-text', to: '/Sms', counter: this.NEW_SMS_COUNT  },
            { title: 'Прокси', icon: 'mdi-arrow-decision', to: '/Proxy' },
        ]
      },
      isLoggedIn : function(){ return this.$store.getters.isLoggedIn},
      authUserData : function(){ return this.$store.state.user},
    },
    methods: {
      logout: function () {
        this.$store.dispatch('logout')
        .then(() => {
          this.$router.push('/login')
        })
      },
      changeTheme: function (darkTheme) {
        localStorage.setItem('darkTheme', darkTheme)
      },
    },
    created: function () {
      this.$http.interceptors.response.use(undefined, function (err) {
        return new Promise(function (resolve, reject) {
          if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
            this.$store.dispatch("logout")
          }
          throw err;
        });
      });
    },
    mounted() {
      this.$root.vsnack = this.$refs.vsnack
    },
  }
</script>