import Vue from "vue";
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

const options = {
    // You can set your default options here
    transition: "Vue-Toastification__fade",
    maxToasts: 6,
    newestOnTop: true,
    closeOnClick: false,
    draggable: false,
    timeout: 9000,
};


Vue.use(Toast, options);