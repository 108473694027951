<template>
  <v-row>
    <v-col
      cols="12"
      sm="7"
      >
      <v-switch
        v-model="notify"
        label="Уведомления"
        @change="changeNotify(notify)"
      ></v-switch>
    </v-col>
    <v-col
      cols="12"
      sm="5"
    >
      <v-switch
        v-model="notifySound"
      >
        <template v-slot:label>
          <v-icon v-if="notifySound">mdi-volume-high</v-icon>
          <v-icon v-else>mdi-volume-off</v-icon>
        </template>
      </v-switch>
    </v-col>
  </v-row>
</template>

<script>
  import { mapActions, mapGetters } from "vuex"
  import NotifySoundUrl from './Notification.mp3'

  export default {
    name: "Notify",
    props: {
      interval:{
        type: Number,
        required: false,
        default: 20000
      },
      soundUrl: {
        type: String,
        required: false,
        default: NotifySoundUrl
      }
    },
    data () {
      return {
        notify: (localStorage.getItem('notify') === 'true') || false,
        lastSMS: localStorage.getItem('lastSMS') || null,
        lastPayment: localStorage.getItem('lastPayment') || null,
        notifySound: (localStorage.getItem('notifySound') === 'true') || false,
      }
    },
    methods:{
      ...mapActions([
        'GET_NOTIFY_API',
      ]),
      getNewPaymants(){
        this.GET_NOTIFY_API({
          params: {
            "payment_from_id": this.lastPayment,
            "payment_limit":10,
            "sms_from_id": this.lastSMS,
            "sms_limit":10,
          }
        });
      },
      stopTimer () {
        if (this.timer) {
          window.clearInterval(this.timer)
        }
      },
      startTimer (interval) {
        this.stopTimer()
        // this.getNewPaymants()
        this.timer = window.setInterval(() => {
          this.getNewPaymants()
        }, interval)
      },
      changeNotify: function (notify) {
        this.notify = notify
        localStorage.setItem('notify', notify)
        if (notify)
          this.startTimer(this.interval)
        else
          this.stopTimer()
      },
      playNotifSound (soundurl=this.soundUrl) {
        if (this.notifySound) {
          let audio = new Audio(soundurl);
          audio.play();
        }
      }
    },
    computed: {
      ...mapGetters([
        'NEW_PAYMENTS',
        'NEW_SMS',
      ]),
      newPayments() {
        return this.NEW_PAYMENTS
      },
      newSMS() {
        return this.NEW_SMS
      },
    },
    watch: {
      newPayments(){
        if ((this.newPayments) && (this.newPayments.length > 0))
        {
          this.playNotifSound()
          for (const pay of this.newPayments) {
              this.$toast.info(`Платеж.\nтип: ${ pay.payment_type }\nдата: ${ pay.payment_time }\nсумма: ${ pay.payment_amount }`
            );
          }
          
          this.lastPayment = this.newPayments[0].id
          localStorage.setItem('lastPayment', this.lastPayment)
        }
      },
      newSMS(){
        if ((this.newSMS) && (this.newSMS.length > 0))
        {
          this.playNotifSound()
          for (const sms of this.newSMS) {
            this.$toast(`SMS.\nотправитель: ${ sms.from_number }\nдата: ${ sms.message_date }\n ${ sms.text }`);
          }

          this.lastSMS = this.newSMS[0].id
          localStorage.setItem('lastSMS', this.newSMS[0].id)
        }
      },
      notifySound () {
        localStorage.setItem('notifySound', this.notifySound)
      }
    },
    created () {
      
    },
    mounted () {
      this.changeNotify(this.notify)
    },
    beforeDestroy () {
      this.stopTimer()
    },
  }
</script>